import logo from '../assets/images/sizzle-side-flame-clean.svg';

const Footer = () => {
  return (
    <footer className="w-full bg-[#171717] text-white py-4 mt-auto">
      <div className="max-w-6xl mx-auto px-4 flex justify-center lg:justify-between items-center">
        <div>
          <a href="/" className="flex items-center">
            <a href="https://sizzle.fi">
              <img src={logo} alt="Logo" className="py-2 w-24 md:w-24" />
            </a>
          </a>
          <p className="text-xs mt-2">A Bool Digital product</p>
        </div>

        <div className="hidden lg:flex items-center">
          <nav className="hidden lg:flex space-x-4 mr-6 text-sm">
            <a href="https://sizzle.fi/" className="hover:text-gray-300">Home</a>
            <a href="https://sizzle.fi/about/" className="hover:text-gray-300">About</a>
            <a href="https://sizzle.fi/blog/" className="hover:text-gray-300">Blog</a>
            <a href="https://sizzle.fi/contact-us/" className="hover:text-gray-300">Contact</a>
            <a href="https://sizzle.fi/terms-of-service/" className="hover:text-gray-300">Terms of Service</a>
            <a href="https://sizzle.fi/privacy-policy/" className="hover:text-gray-300">Privacy Policy</a>
          </nav>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
