import { Menu, MenuButton, MenuList, MenuItem, useDisclosure, CircularProgress, Icon } from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons"
import { IoMdContact } from "react-icons/io";
import { BsFillQuestionSquareFill } from "react-icons/bs";
import { FaSquareXTwitter } from "react-icons/fa6";
import { IoLogoDiscord } from "react-icons/io5";
import { RiBankFill, RiQuestionLine } from 'react-icons/ri';
import ConnectWalletButton from "./ConnectWalletButton";
import { useAccount, useDisconnect } from "wagmi";
import { useWeb3Modal } from "@web3modal/react";
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { useWalletContext } from "../hooks/useWalletContext";
import { FaEye } from "react-icons/fa";
import ConnectWalletModal from "./ConnectWalletModal";
import { useLocation, useNavigate } from "react-router";

import { NavLink } from "react-router-dom";

// Export these from one path
import defiIcon from '../assets/icons/nav/defi.svg';
import homeIcon from '../assets/icons/nav/home.svg';
import logo from '../assets/images/sizzle-side-flame-clean.svg';
import greenDot from '../assets/icons/nav/green-dot.svg';
import { trackEvent } from "../helpers/googleAnalyticsHelpers";
import useIsMobile from "../hooks/general/useIsMobile";

const NavBar = () => {
    const { isConnected, address } = useAccount();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: web3ModalOpen } = useWeb3Modal();
    const { disconnect } = useDisconnect();
    const { setWalletAddress, setReadOnly, walletAddress, readOnly } = useWalletContext();
    const location = useLocation();
    const isMobile = useIsMobile();

    const handleDisconnect = () => {
        if (readOnly) {
            setReadOnly(false);
        }
        setWalletAddress();
        disconnect();
    }

    return (
        <div className="w-full bg-[#171717]">
            <div className="flex justify-between items-center px-2 py-1">
                <nav className="flex gap-6 items-center">
                    <a href="https://sizzle.fi">
                        <img src={logo} alt="Logo" className="py-2 w-24 md:w-24" />
                    </a>
                    {!isMobile ?
                        <>
                            <NavLink
                                to="/"
                                className={({ isActive }) => (`flex gap-2 items-center flex-shrink-0 ${isActive ? 'text-[#171717]' : 'text-white'} ${isActive ? 'bg-[#73B0BE] rounded-[8px] px-2 py-1' : ''}`)}
                            >
                                <span className="text-xl font-primary">Home</span>
                            </NavLink>
                            <a
                                href="https://sizzle.fi/about/"
                                className="hidden md:flex gap-2 items-center text-white flex-shrink-0"
                            >
                                <span className="text-xl font-primary">About</span>
                            </a>
                            <a
                                href="https://sizzle.fi/contact-us/"
                                className="flex gap-2 items-center text-white flex-shrink-0"
                            >
                                <span className="text-xl font-primary">Contact Us</span>
                            </a>
                        </>
                        : <></>
                    }
                </nav>
                <div className="flex gap-2 md:gap-4 items-center">
                    <a
                        href="https://twitter.com/SizzleFi"
                        className="text-white flex-shrink-0 hidden md:block "
                    >
                        <FaSquareXTwitter size={'32px'} />
                    </a>
                    <a
                        href="https://discord.gg/Rn7agwMT"
                        className="text-white flex-shrink-0 hidden md:block "
                    >
                        <IoLogoDiscord size={'32px'} />
                    </a>
                    {(isConnected && address) || (readOnly && walletAddress) ?
                        <Menu>
                            <MenuButton>
                                <div className="flex items-center border-2 border-[#73B0BE] font-primary md:font-black rounded-[8px] px-2 md:p-1">
                                    {readOnly ?
                                        <Icon as={FaEye} mr={2} /> :
                                        <img src={greenDot} alt="Green Dot" className="mr-2" />
                                    }
                                    {`${(walletAddress)?.substring(0, 5)}...${(walletAddress)?.slice((walletAddress).length - 5)}`}
                                </div>
                            </MenuButton>
                            <MenuList bg="black">
                                <MenuItem bg="black" onClick={() => onOpen()}>Connect New Wallet</MenuItem>
                                <MenuItem bg="black" onClick={handleDisconnect}>Disconnect</MenuItem>
                            </MenuList>
                        </Menu>

                        : <button className="flex items-center border-2 border-[#73B0BE] md:h-[2em] rounded-[8px] px-2 py-1" onClick={() => {
                            onOpen();
                            trackEvent({
                                category: 'connect_wallet',
                                action: 'open_connect_modal',
                                label: 'Top Navbar Connect Button',
                                additionalParams: {
                                    'source': 'navbar'
                                }
                            });
                        }}>
                            {web3ModalOpen || isOpen ?
                                <>
                                    <CircularProgress isIndeterminate size="1rem" color="mint.400" mr={2} />
                                    {"Connecting..."}
                                </>
                                : 'Connect'}
                        </button>
                    }
                    {isMobile ?
                        <Menu>
                            <MenuButton>
                                <div className="flex items-center font-primary md:font-black rounded-[8px] py-1 px-2">
                                    <HamburgerIcon boxSize={'20px'} />
                                </div>
                            </MenuButton>
                            <MenuList bg="black">
                                <MenuItem bg={location.pathname === '/' ? '#73B0BE' : 'black'}>
                                    <NavLink
                                        to="/"
                                        className="flex gap-2 items-center text-white flex-shrink-0"
                                    >
                                        <span className="text-md font-primary">Home</span>
                                    </NavLink>
                                </MenuItem>
                                <MenuItem bg="black">
                                    <NavLink
                                        to="https://sizzle.fi/about/"
                                        className="flex gap-2 items-center text-white flex-shrink-0"
                                    >
                                        <span className="text-md font-primary">About</span>
                                    </NavLink>
                                </MenuItem>
                                <MenuItem bg="black">
                                    <NavLink
                                        to="https://sizzle.fi/contact-us/"
                                        className="flex gap-2 items-center text-white flex-shrink-0"
                                    >
                                        <span className="text-md font-primary">Contact Us</span>
                                    </NavLink>
                                </MenuItem>
                                <MenuItem bg="black">
                                    <NavLink
                                        to="https://twitter.com/SizzleFi"
                                        target="_blank"
                                        className="text-white flex gap-2 items-center"
                                    >
                                        <FaSquareXTwitter size={'18px'} />
                                        Twitter
                                    </NavLink>
                                </MenuItem>
                                <MenuItem bg="black">
                                    <NavLink
                                        to="https://discord.gg/Rn7agwMT"
                                        target="_blank"
                                        className="text-white flex gap-2 items-center"
                                    >
                                        <IoLogoDiscord size={'18px'} />
                                        Discord
                                    </NavLink>
                                </MenuItem>
                            </MenuList>
                        </Menu>
                        :
                        <>
                        </>
                    }
                </div>
                <ConnectWalletModal isOpen={isOpen} onClose={onClose} />
            </div>
        </div>
    );
}

export default NavBar;
