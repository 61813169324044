import { NavigateFunction } from "react-router-dom";

export function formatNumbers(num: number | string | undefined) {
    if (!num) return;

    if (typeof num === 'string') {
        return new Intl.NumberFormat('en-US', { maximumFractionDigits: 4 }).format(parseFloat(num));
    }
    return new Intl.NumberFormat('en-US', { maximumFractionDigits: 4 }).format(num);
}

export function formatLargeNumber(num: number | string | undefined): string {
    if (!num) return '0';

    if (typeof num === 'string') {
        num = parseFloat(num);
    }
    if (num < 1000) return num.toString();
    const units = ['K', 'M', 'B', 'T'];
    let decimal;

    const exp = Math.floor(Math.log(num) / Math.log(1000));

    const value = num / Math.pow(1000, exp);
    if (exp > 0) {
        decimal = value.toFixed(2);
        return `${decimal}${units[exp - 1]}`;
    } else {
        return '0';
    }
}

export const updateUrlParams = (navigate: NavigateFunction, pathname: string | undefined, params: any) => {
    navigate({
        pathname: pathname,
        search: `?${new URLSearchParams(params).toString()}`,
    }, { replace: true });
};

export function formatEthNumber(num: number, price: number) {
    return ((num / 1e18) * (price || 0))
}