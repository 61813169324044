import { Box, Button, Flex, Modal, ModalBody, ModalContent, ModalOverlay, Text, Image, useDisclosure, Heading, CircularProgress, Center, Tooltip, Icon } from "@chakra-ui/react";
import TokenSelect, { TokenSelectHandle } from "../../TokenSelect";
import { useEffect, useRef, useState } from "react";
import { DefiRate, WatchlistToken } from "@BoolDigital/sizzle-types";
import { formatRateLabel } from "../../../helpers/watchlistHelpers";
import { ConfigVariables } from "../../../config";
import { RiQuestionLine } from "react-icons/ri";
import { trackEvent } from "../../../helpers/googleAnalyticsHelpers";
import { useWalletContext } from "../../../hooks/useWalletContext";
import { ActionButton } from "../../global/buttons/ActionButton";

interface AddWatchlistTokenProps {
    user: any,
    watchlist: WatchlistToken[] | null,
    onReloadWatchlist: () => void,
}

const AddWatchlistToken: React.FC<AddWatchlistTokenProps> = ({ user, watchlist, onReloadWatchlist }) => {
    const [selectedToken, onTokenSelected] = useState<any>();
    const setSelectedToken = (token: any) => {
        onTokenSelected(token);
        trackEvent({
            category: 'watchlist',
            action: 'select-token',
            label: 'Select Token Dropdown',
            additionalParams: {
                'source': 'add-token',
                'wallet_address': walletAddress,
                'token_address': token?.address
            }
        });
    }
    const { walletAddress } = useWalletContext();
    const [defiRates, setDefiRates] = useState<DefiRate[]>([]);
    const [addWrappedToken, setAddWrappedToken] = useState<boolean>(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [selectedDefiRateIds, setSelectedDefiRateIds] = useState<string[]>([]);
    const [fetchingRates, setFetchingRates] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    // check if token already selected
    const tokenAlreadyAdded = watchlist?.some((t) => t.token.symbol === selectedToken?.symbol && t.token.address === selectedToken?.address);

    const customScrollbar = {
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            background: 'rgba(45, 45, 45, 0.6)',
            borderRadius: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '8px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
        },
        '&::-webkit-scrollbar-button': {
            display: 'none',
        },
    };

    const tokenSelectRef = useRef<TokenSelectHandle>(null);

    useEffect(() => {
        setDefiRates([]);
        setSelectedDefiRateIds([]);
    }, [isOpen])

    useEffect(() => {
        if (!selectedToken) {
            return;
        }
        setAddWrappedToken(false);
        if (selectedToken?.symbol === 'eth' && selectedToken?.address === null) {
            setAddWrappedToken(true);
        }
        setDefiRates([]);
        setFetchingRates(true);
        fetch(`${ConfigVariables.SERVER_URL}/defi/protocols/rates/${selectedToken.address}`)
            .then(response => response.json())
            .then(data => {
                setDefiRates(data ?? []);
                setFetchingRates(false);
            })
            .catch((e) => {
                console.log(`error fetching rates: ${e}`)
                setFetchingRates(false);
            });

    }, [selectedToken]);

    function toggleRateSelection(id: string) {
        setSelectedDefiRateIds(prevIds => {
            if (prevIds.includes(id)) {
                trackEvent({
                    category: 'watchlist',
                    action: 'deselect-defi-rate',
                    label: 'Deselect DeFi Rate to Unwatch',
                    additionalParams: {
                        'source': 'add-token',
                        'wallet_address': walletAddress,
                        'token_address': selectedToken?.address,
                        'rate_key': id,
                    }
                });
                return prevIds.filter(rateId => rateId !== id);
            } else {
                trackEvent({
                    category: 'watchlist',
                    action: 'select-defi-rate',
                    label: 'Select DeFi Rate to Watch',
                    additionalParams: {
                        'source': 'add-token',
                        'wallet_address': walletAddress,
                        'token_address': selectedToken?.address,
                        'rate_key': id,
                    }
                });
                return [...prevIds, id];
            }
        });
    }

    async function saveAddToken() {
        const selectedDefiRates = selectedDefiRateIds.map((id) => {
            return defiRates.find((r) => r.key === id);
        })

        const payload = {
            userAddress: user.address,
            token: selectedToken,
            rates: selectedDefiRates.filter((r) => r),
        }

        setSaveLoading(true);
        try {
            const response = await fetch(`${ConfigVariables.SERVER_URL}/defi/add-watchlist-token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            const data = await response.json();
            onReloadWatchlist();
            trackEvent({
                category: 'watchlist',
                action: 'save-new-watchlist-token',
                label: 'Save New Watchlist Token',
                additionalParams: {
                    'source': 'add-token',
                    'wallet_address': walletAddress,
                    'token_address': selectedToken?.address,
                    'number_of_watched_rates': selectedDefiRateIds?.length,
                    'success': true
                }
            });
            // if need to include WETH, reset state of modal to show for WETH, else close modal
            if (addWrappedToken) {
                await tokenSelectRef.current?.selectWETH();
            } else {
                onClose();
            }
        } catch (e) {
            trackEvent({
                category: 'watchlist',
                action: 'save-new-watchlist-token',
                label: 'Save New Watchlist Token',
                additionalParams: {
                    'source': 'add-token',
                    'wallet_address': walletAddress,
                    'token_address': selectedToken?.address,
                    'number_of_watched_rates': selectedDefiRateIds?.length,
                    'success': false
                }
            });
            console.log(`error saving token: ${e}`);
        } finally {
            setSaveLoading(false);
        }
        setSaveLoading(false);
    };

    return (
        <Box>
            <ActionButton style="primary" classes="bg-[mint.400] font-semibold px-2 py-1" onClick={() => {
                onOpen();
                trackEvent({
                    category: 'watchlist',
                    action: 'open-add-watchlist-token-modal',
                    label: 'Add Watchlist Token Button',
                    additionalParams: {
                        'wallet_address': walletAddress,
                    }
                });
            }}>Add Token</ActionButton>
            <Modal isOpen={isOpen} onClose={() => {
                setDefiRates([]);
                setSelectedDefiRateIds([]);
                onTokenSelected(undefined);
                onClose();
            }} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalBody p={0}>
                        <Heading pr={4} pl={4} pt={4} size={'sm'}>Add Token to Watchlist</Heading>
                        <Box p={4}>
                            <TokenSelect ref={tokenSelectRef} onTokenSelected={setSelectedToken} />
                            {
                                selectedToken?.symbol === 'eth' && selectedToken?.address === null &&
                                <div className="flex items-center gap-2 mt-1">
                                    <input
                                        type="checkbox"
                                        id="includeWETH"
                                        name="includeWETH"
                                        className="w-4 h-4"
                                        checked={addWrappedToken}
                                        onChange={(e) => setAddWrappedToken(e.target.checked)}
                                    />
                                    <label htmlFor="includeWETH" className="text-sm font-medium">Include WETH</label>
                                    <Tooltip
                                        hasArrow
                                        label="Most DeFi protocols use the ERC20 version of Ethereum, 
                                            Wrapped Ethereum or WETH. If you check this box, you will be 
                                            able to choose and add rates to watch for WETH after you finish adding ETH."
                                        aria-label="Total Fees Available to Collect">
                                        <span className="cursor-pointer">
                                            <Icon as={RiQuestionLine} boxSize={4} />
                                        </span>
                                    </Tooltip>
                                </div>
                            }
                        </Box>
                        {
                            selectedToken ?
                                <Box maxH={'300px'} overflowY={'scroll'} p={4} sx={customScrollbar}>
                                    <Text>Available Protocols</Text>
                                    {
                                        fetchingRates && <Center><CircularProgress color={'mint.500'} isIndeterminate /></Center>
                                    }
                                    {
                                        defiRates.map((r) => {
                                            const isSelected = selectedDefiRateIds.includes(r.key ?? '-1');

                                            return (
                                                <Flex key={r.key}
                                                    align="center"
                                                    gap={4}
                                                    justifyContent={'space-between'}
                                                    bg={isSelected ? "mint.300" : undefined}
                                                    textColor={isSelected ? "black" : undefined}
                                                    borderRadius="md"
                                                    p={2}
                                                    mt={2}
                                                    cursor="pointer"
                                                    _hover={{ bg: !isSelected ? "mint.100" : "red.100", textColor: "black" }}
                                                    onClick={() => toggleRateSelection(r.key ?? '')}
                                                >
                                                    <Flex alignItems={'center'} gap={4}>
                                                        <Image src={r.protocolLogoUrl} alt={r.protocol} boxSize="32px" />
                                                        <Text fontSize={'sm'}>{formatRateLabel(r)}</Text>
                                                    </Flex>
                                                    <Text fontSize={'sm'}>{r.displayRate}%</Text>
                                                </Flex>
                                            );
                                        })
                                    }
                                </Box>
                                : <></>
                        }
                        <Box m={4}>
                            <Button
                                w={'100%'}
                                bgColor={'mint.500'}
                                onClick={saveAddToken}
                                isDisabled={saveLoading || tokenAlreadyAdded}
                            >{saveLoading ?
                                <CircularProgress isIndeterminate color={'mint.300'} size={'20px'} />
                                : tokenAlreadyAdded ? 'Token Already Added' : 'Add Token'}</Button>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    )
}

export default AddWatchlistToken;