import NavBar from "./NavBar"
import DAppSelectionBanner from "./DAppSelectionBanner"
import CookieConsent, { Cookies } from "react-cookie-consent";
import { Outlet } from "react-router";
import { useNetwork } from "wagmi";
import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalContent, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { useWalletContext } from "../hooks/useWalletContext";
import { ActionButton } from "./global/buttons/ActionButton";
import { ConfigVariables } from "../config";
import { SizzleAnimated } from "./global/SizzleAnimated";
import { usePageTracking } from "../helpers/googleAnalyticsHelpers";
import Footer from "./Footer";

const MainLayout = () => {
    const { chain } = useNetwork();
    const { walletAddress, readOnly } = useWalletContext();
    const [wrongNetwork, setWrongNetwork] = useState(false);

    useEffect(() => {
        if (chain && ![1].includes(chain.id)) {
            setWrongNetwork(true);
        } else {
            setWrongNetwork(false);
        }
    });

    usePageTracking();

    return (
        <div className="main-layout flex flex-col min-h-[100vh]">
            <div className="content flex-1">
                {wrongNetwork && (
                    <div className="w-full bg-red-600 text-white p-4 z-50 flex items-center justify-center">
                        Unsupported chain. Please switch to a supported network.
                    </div>
                )}
                <NavBar />
                <CookieConsent
                    enableDeclineButton
                    buttonStyle={{ backgroundColor: '#62a7b6', color: '#FFFFFF' }}
                    declineButtonStyle={{ border: '2px solid #62a7b6', backgroundColor: 'transparent', color: '#62a7b6' }}
                    onAccept={() => {
                        Cookies.set("app-user-cookie-consent", "true", { expires: 365 });
                    }}
                    onDecline={() => {
                        Cookies.set("app-user-cookie-consent", "false", { expires: 365 });
                    }}
                >We use cookies to help us provide a better product and experience for you.</CookieConsent>
                <div className="main-content px-4">
                    {
                        // <div className="items-center w-full">
                        //     <DAppSelectionBanner handleProtocolSelect={() => { }} />
                        // </div>
                    }
                    <Outlet />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default MainLayout;