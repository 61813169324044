import React, { useState } from "react";
import { ReserveDataHumanized } from "@aave/contract-helpers";
import { ComputedUserReserve, FormatReserveUSDResponse, FormatUserSummaryResponse } from "@aave/math-utils";
import { Icon, Tooltip, useDisclosure } from "@chakra-ui/react";
import {
    MdOutlineKeyboardArrowDown,
    MdOutlineKeyboardArrowUp,
    MdOutlineQuestionMark,
} from "react-icons/md";
import { RiQuestionLine } from "react-icons/ri";
import { processBorrows, processSupplies } from "../../../helpers/aaveHelpers";
import TokenName from "../../Home/TokenName";
import { BitqueryToken } from "@BoolDigital/sizzle-types";
import { useEffect } from "react";
import { Switch } from '../../../components/global/Switch'
import { ActionButton } from "../../global/buttons/ActionButton";
import { useNavigate, useLocation, Outlet } from 'react-router';
import { useWalletContext } from "../../../hooks/useWalletContext";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import DotLoader from "../../global/DotLoader";
import lidoLogo from '../../../assets/images/protocol-logos/lido-lido-logo.png';


interface LidoPositionsHomeProps {
    summary?: any,
    startOpen?: boolean,
    hideHeader?: boolean,
    loading?: boolean,
}

const LidoPositionsHome: React.FC<LidoPositionsHomeProps> = (props) => {
    const { walletAddress, readOnly } = useWalletContext();
    const { isOpen, onToggle, onOpen } = useDisclosure();
    const navigate = useNavigate();

    useEffect(() => {
        if (props.startOpen) {
            onOpen();
        }
    }, [])

    return (
        <section className="font-primary">
            {!props.hideHeader &&
                <header className="flex gap-2 items-center py-2">
                    <img src={lidoLogo} alt="Lido logo" className="w-[32px] h-[32px] rounded-full   " />
                    <h1 className="text-2xl py-4">Lido</h1>

                </header>
            }
            {props.loading ? <DotLoader /> :
                <>
                    <div className="flex gap-4 lg:gap-8 pb-4">
                        <section className="flex-shrink-0">
                            <div>
                                <div className="flex gap-1">
                                    <p className="md:text-xl underline underline-offset-2 decoration-2 pb-2">stETH Balance</p>
                                </div>
                                <p className="md:text-xl font-bold relative">
                                    <span className="pl-2">
                                        {parseFloat(props.summary?.stEthBalance ?? '0').toFixed(2)}
                                    </span>
                                </p>
                                <p className="md:text-xl relative text-gray-400">
                                    <sup className="text-xs absolute top-0 left-0 pr-1">$</sup>
                                    <span className="pl-2">
                                        {parseFloat(props.summary?.usdBalance ?? '0').toFixed(2)}
                                    </span>
                                </p>
                            </div>
                        </section>
                        <section className="flex-shrink-0">
                            <div className="flex gap-1">
                                <p className="md:text-xl underline underline-offset-2 decoration-2 pb-2">stETH Rewarded</p>
                                <Tooltip hasArrow label="The total amount of stETH rewarded through rebase events for this wallet address.">
                                    <span className="cursor-pointer">
                                        <Icon as={RiQuestionLine} boxSize={4} />
                                    </span>
                                </Tooltip>
                            </div>
                            <p className="md:text-xl font-bold">{parseFloat(props.summary?.ethRewards ?? '0').toFixed(4)}</p>
                            <p className="md:text-xl relative text-gray-400">
                                <sup className="text-xs absolute top-0 left-0 pr-1">$</sup>
                                <span className="pl-2">
                                    {parseFloat(props.summary?.usdRewards ?? '0').toFixed(2)}
                                </span>
                            </p>
                        </section>
                        <section className="flex-shrink-0">
                            <div className="flex gap-1">
                                <p className="md:text-xl underline underline-offset-2 decoration-2 pb-2">Average APR</p>
                                <Tooltip hasArrow label="The average APR earned over the all of the rebase reward events.">
                                    <span className="cursor-pointer">
                                        <Icon as={RiQuestionLine} boxSize={4} />
                                    </span>
                                </Tooltip>
                            </div>
                            <p className="md:text-xl font-bold">{parseFloat(props.summary?.avgApr ?? '0').toFixed(2)}%</p>
                        </section>
                    </div>
                    <Outlet />
                </>
            }
        </section>
    );
}

export default LidoPositionsHome;
