import { Box, Flex, Text } from "@chakra-ui/react";
import { DefiRate, WatchlistToken } from "@BoolDigital/sizzle-types";
import { formatNumbers, formatEthNumber, formatLargeNumber } from "./general";
import { RiInformationLine } from "react-icons/ri";

export function formatRateLabel(rate: DefiRate): string {
    switch (rate.protocol) {
        case 'aave':
            return `${rate.protocolRateData.tokenSymbol}`
        case 'compound':
            return `${rate.protocolRateData.tokenSymbol}`
        case 'uniswap':
            return `${rate.protocolRateData.token0Symbol} / ${rate.protocolRateData.token1Symbol} (${rate.protocolRateData.feeTier / 10000}%)`
        case 'lido':
        case 'rocketpool':
            return `${rate.protocolRateData.tokenSymbol}`
        default:
            return '';
    }
}

export function capitalizeWords(input: string): string {
    return input.split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
}

export function formatRateTooltip(rate: any, token?: WatchlistToken) {
    switch (rate.protocol) {
        case 'aave': {
            const data = rate.protocolRateData;
            return (
                <Box>
                    <Flex alignItems={'center'} mb={2} gap={1}>
                        <RiInformationLine />
                        <Text fontSize={'smaller'}>Aave Supply APY for this asset</Text>
                    </Flex>
                    <Flex justify={'space-between'} gap={8}>
                        <Box>
                            <Text fontWeight={'bold'}>Total Supplied</Text>
                            <Text>{formatNumbers(data.totalSupplied)}</Text>
                            <Text color={'whiteAlpha.700'}>${formatNumbers(data.totalSuppliedUSD)}</Text>
                        </Box>
                        <Box>
                            <Text fontWeight={'bold'}>Total Borrowed</Text>
                            <Text>{formatNumbers(data.totalDebt)}</Text>
                            <Text color={'whiteAlpha.700'}>${formatNumbers(data.totalDebtUSD)}</Text>
                        </Box>
                    </Flex>
                </Box>
            );
        }
        case 'uniswap': {
            const data = rate.protocolRateData;
            return (
                <Box mt={4}>
                    <Flex alignItems={'flex-start'} mb={2} gap={1}>
                        <Box>
                            <RiInformationLine />
                        </Box>
                        <Text fontSize={'smaller'}>Estimated APR from total fees collected across all liquidity ranges over the past 7 days,
                            relative to current TVL. Does not factor in concentrated liquidity ranges. Results may vary.</Text>
                    </Flex>
                    <Flex justify={'space-between'} gap={8}>
                        <Text fontWeight={'bold'}>{data.token0Symbol} / {data.token1Symbol}</Text>
                        <Box>
                            <Text>Fee Tier: {data.feeTier / 10000}%</Text>
                        </Box>
                    </Flex>
                    <Flex gap={8} mt={4}>
                        <Box>
                            <Text fontWeight={'bold'}>Ttl Value Locked</Text>
                            <Text>${formatNumbers(data.tvl)}</Text>
                        </Box>
                        <Box>
                            <Text fontWeight={'bold'}>Volume (Today)</Text>
                            <Text>${formatNumbers(data.volumeUSD)}</Text>
                        </Box>
                    </Flex>
                </Box>
            );
        }
        case 'compound':
            const data = rate.protocolRateData;
            const isWeth = token?.token.address == "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2";
            return (
                <Box mt={4}>
                    <Flex alignItems={'flex-start'} mb={2} gap={1}>
                        <Box>
                            <RiInformationLine />
                        </Box>
                        <Text fontSize={'smaller'}>The Net Borrow APR for this asset.
                        </Text>
                    </Flex>
                    <Flex justify={'space-between'} gap={8}>
                        <Box>
                            <Text fontWeight={'bold'}>Total Earning</Text>
                            <Text>
                                {
                                    isWeth ?
                                        `$${formatNumbers(data?.tvlUsd * (token?.currentPrice || 0))}`
                                        :
                                        `$${formatNumbers(data?.tvlUsd)}`
                                }
                            </Text>
                        </Box>
                        <Box>
                            <Text fontWeight={'bold'}>Total Borrowing</Text>
                            <Text>
                                {
                                    isWeth ?
                                        `$${formatNumbers(formatEthNumber(data?.totalBorrow, token?.currentPrice))}`
                                        :
                                        `$${formatNumbers(data?.totalBorrow)}`
                                }
                            </Text>
                        </Box>
                    </Flex>
                </Box >
            );
        case 'lido': {
            const data = rate.protocolRateData;
            return (
                <Box>
                    <Flex alignItems={'center'} mb={2} gap={1}>
                        <RiInformationLine />
                        <Text fontSize={'smaller'}>APR is moving average for past 7 days.</Text>
                    </Flex>
                    <Flex justify={'space-between'} gap={8}>
                        <Box>
                            <Text fontWeight={'bold'}>Total Supplied</Text>
                            <Text>{formatNumbers(data.totalStaked)}</Text>
                            <Text color={'whiteAlpha.700'}>${formatNumbers(data.totalStakedUSD)}</Text>
                        </Box>
                    </Flex>
                </Box>
            );
        }
        case 'rocketpool': {
            const data = rate.protocolRateData;

            return (
                <Box>
                    <Flex alignItems={'center'} mb={2} gap={1}>
                        <RiInformationLine />
                        <Text fontSize={'smaller'}>APR is moving average for past 7 days.</Text>
                    </Flex>
                    <Flex gap={8}>

                        <Box>
                            <Text fontWeight={'bold'}>Protocol Liquidity</Text>
                            <Text>{formatLargeNumber(data.totalCollateral)} ETH</Text>
                        </Box>
                        <Box>
                            <Text fontWeight={'bold'}>Exchange Rate</Text>
                            <Text>1 rETH = {parseFloat(data?.exchangeRate).toFixed(4)} ETH</Text>
                        </Box>
                    </Flex>
                </Box>
            );
        }
    }
    return JSON.stringify(rate)
}