import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import DashChart from './DashChart';
import Positions from './Positions';
import { useWalletContext } from '../../hooks/useWalletContext';
import Watchlist from './watchlist/Watchlist';
import { Outlet } from 'react-router-dom';
import { ChartRange, DashboardChartToken } from '@BoolDigital/sizzle-types';
import { trackEvent } from '../../helpers/googleAnalyticsHelpers';
import HoldingsWatchlistTab from './HoldingsWatchlistTab';

const UserPortfolioDashboard: React.FC = (props) => {
  const { walletAddress } = useWalletContext();
  const [chartRange, setChartRange] = useState<ChartRange>('1W');
  const [selectedToken, setSelectedToken] = useState<DashboardChartToken | undefined>();
  const [isHoldings, setIsHoldings] = useState<boolean>(true)
  const [watchlist, setWatchlist] = useState<any>();
  const chartProps = { selectedToken, chartRange, setChartRange };
  const [displayPricelessToken, setDisplayPricelessToken] = useState<boolean>(true);

  const handleTokenSelection = (token: DashboardChartToken) => {
    if (token.address === selectedToken?.address) {
      if (!walletAddress) {
        setSelectedToken({
          address: "0x",
          symbol: "eth"
        })
      } else {
        setSelectedToken(undefined)
      }
      trackEvent({
        category: 'dash-chart',
        action: 'deselect-token',
        label: 'Deselect Selected Token',
        additionalParams: {
          'token_address': token.address,
        }
      });
    } else {
      setSelectedToken(token);
      trackEvent({
        category: 'dash-chart',
        action: 'select-token',
        label: 'Select Token',
        additionalParams: {
          'token_address': token.address,
        }
      });
    }
  }

  useEffect(() => {
    if (!walletAddress) {
      setSelectedToken({
        address: "0x",
        symbol: "eth"
      })
    } else {
      setSelectedToken(undefined)
    }
  }, [walletAddress])

  if (walletAddress) {
    return (
      <Box>
        <Box w={'100%'} h={'30vh'} mb={4} mt={4}>
          <DashChart {...chartProps} displayPricelessToken={displayPricelessToken} />
        </Box>
        <Outlet />
        <HoldingsWatchlistTab
          handleTokenSelection={handleTokenSelection}
          selectedToken={selectedToken}
          setDisplayPricelessToken={setDisplayPricelessToken}
          displayPricelessToken={displayPricelessToken}
          setWatchlist={setWatchlist}
        />
        <Positions />
      </Box>
    );
  } else {
    return (
      <Box w={'100%'} h={'30vh'} mb={4} mt={4}>
        <DashChart {...chartProps} displayPricelessToken={displayPricelessToken} />
        <div className="my-4">
          <h2 className="text-2xl font-bold">Watchlist</h2>
          <Watchlist selectedToken={selectedToken} setSelectedToken={handleTokenSelection} setWatchlist={setWatchlist} />
        </div>
      </Box>
    );
  }
};

export default UserPortfolioDashboard;