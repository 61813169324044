import { Collapse, useDisclosure, Tooltip, Icon } from "@chakra-ui/react";
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";
import { RiQuestionLine } from "react-icons/ri";
import { useEffect, useState } from "react";
import DotLoader from "../../global/DotLoader";
import { useWalletContext } from "../../../hooks/useWalletContext";
import { Switch } from "../../global/Switch";
import { ethers } from "ethers";
// import CompoundTokenDisplay from "./CompoundTokenDisplay";
import { formatLargeNumber, formatNumbers } from "../../../helpers/general";
import { SizzleAnimated } from "../../global/SizzleAnimated";
import rocketPoolIcon from "../../../assets/images/protocol-logos/rocket-pool-icon.png";

interface RocketPoolProps {
  loading?: boolean;
  summary: {
    apr: string;
    earnings: number;
    ethValue: number;
    exchangeRate: string;
    rethBalance: string;
    totalCollateral: string;
  }
}
const RocketPoolPositionsHome: React.FC<RocketPoolProps> = (props) => {
  console.log(props)
  const userInfo = props.summary

  console.log(`User Info: ${JSON.stringify(userInfo)}`)
  return (
    <section className="font-primary">
      {/* {!props.hideHeader && ( */}
      <header className="flex gap-2 items-center py-2">
        <img src={rocketPoolIcon} alt="RocketPool logo" className="w-[32px] h-[32px]" />
        <h1 className="text-2xl py-4">RocketPool</h1>
      </header>
      {!props.loading && userInfo ?
        <>
          <div className="flex gap-4 lg:gap-8 w-100 flex-wrap">
            <section className="flex-shrink-0">
              <h3 className="md:text-xl underline underline-offset-2 decoration-2 pb-2">rETH Balance</h3>

              <h3 className="md:text-xl font-bold relative">
                <sup className="text-xs absolute top-0 left-0 pr-1">Ξ</sup>
                <span className="pl-2">
                  {parseFloat(userInfo?.rethBalance).toFixed(4)}
                </span>
              </h3>
            </section>
            <section className="flex-shrink-0">
              <div className="flex gap-1">
                <h3 className="md:text-xl underline underline-offset-2 decoration-2 pb-2">ETH Value</h3>
                <Tooltip hasArrow label="The value of rETH if exchanged for ETH within the protocol."
                  aria-label="The value of rETH if exchanged for ETH within the protocol">
                  <span className="cursor-pointer">
                    <Icon as={RiQuestionLine} boxSize={4} />
                  </span>
                </Tooltip>
              </div>
              <h3 className="md:text-xl font-bold relative">
                <sup className="text-xs absolute top-0 left-0 pr-1">Ξ</sup>
                <span className="pl-2">
                  {parseFloat(userInfo?.ethValue.toFixed(4))}
                </span>
              </h3>
            </section>
            <section className="flex-shrink-0">
              <div className="flex gap-1">
                <h3 className="md:text-xl underline underline-offset-2 decoration-2 pb-2">APR</h3>
                <Tooltip hasArrow label="The 7 day average APR earned through appreciation of the rETH asset with regards to ETH"
                  aria-label="The 7 day average APR earned through appreciation of the rETH asset with regards to ETH">
                  <span className="cursor-pointer">
                    <Icon as={RiQuestionLine} boxSize={4} />
                  </span>
                </Tooltip>
              </div>
              <h3 className="md:text-xl font-bold relative">
                {parseFloat(userInfo?.apr).toFixed(2)}%
              </h3>
            </section>
            <section className="flex-shrink-0">
              <div className="flex gap-1">
                <h3 className="md:text-xl underline underline-offset-2 decoration-2 pb-2">Exchange Rate</h3>
              </div>
              <h3 className="md:text-xl font-bold relative">
                1 ETH = {parseFloat(userInfo?.exchangeRate).toFixed(4)} rETH
              </h3>
            </section>
          </div>
        </>
        :
        <DotLoader />
      }
    </section>
  );
}

export default RocketPoolPositionsHome;