import React, { useState } from "react";
import { ReserveDataHumanized } from "@aave/contract-helpers";
import { ComputedUserReserve, FormatReserveUSDResponse, FormatUserSummaryResponse } from "@aave/math-utils";
import { Icon, Tooltip, useDisclosure } from "@chakra-ui/react";
import {
    MdOutlineKeyboardArrowDown,
    MdOutlineKeyboardArrowUp,
    MdOutlineQuestionMark,
} from "react-icons/md";
import { RiQuestionLine } from "react-icons/ri";
import { processBorrows, processSupplies } from "../../../helpers/aaveHelpers";
import TokenName from "../../Home/TokenName";
import { BitqueryToken } from "@BoolDigital/sizzle-types";
import { useEffect } from "react";
import { Switch } from '../../../components/global/Switch'
import { ActionButton } from "../../global/buttons/ActionButton";
import { useNavigate, useLocation, Outlet } from 'react-router';
import { useWalletContext } from "../../../hooks/useWalletContext";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import DotLoader from "../../global/DotLoader";
import aaveLogo from '../../../assets/images/protocol-logos/aave-aave-logo.png';

interface AavePositionsHomeProps {
    summary?: FormatUserSummaryResponse<ReserveDataHumanized & FormatReserveUSDResponse>,
    startOpen?: boolean,
    hideHeader?: boolean,
    hideToggle?: boolean,
    loading?: boolean,
}

const AavePositionsHome: React.FC<AavePositionsHomeProps> = (props) => {
    const { walletAddress, readOnly } = useWalletContext();
    const { isOpen, onToggle, onOpen } = useDisclosure();
    const supplies = processSupplies(props.summary);
    const borrows = processBorrows(props.summary);
    const navigate = useNavigate();

    useEffect(() => {
        if (props.startOpen) {
            onOpen();
        }
    }, [])

    const showAsset = async (e: React.MouseEvent, token: ComputedUserReserve<ReserveDataHumanized & FormatReserveUSDResponse>, action?: string) => {
        e.stopPropagation();

        const targetElement = e.target as HTMLElement;

        if (targetElement?.id === "aave-switch" || targetElement.closest("#aave-switch")) {
            return; // Return early when the click event occurs on the Switch element
        } else {
            window.scrollTo({
                top: 280,
                behavior: "smooth", // Adds a smooth scrolling animation
            });

            navigate(`/aave/${token.underlyingAsset}/${action ?? "supply"}`);
        }
    }

    return (
        <section className="font-primary">
            {!props.hideHeader &&
                <header className="flex gap-2 items-center py-2">
                    <img src={aaveLogo} alt="Aave logo" className="w-[32px] h-[32px]" />
                    <h1 className="text-2xl py-4">Aave</h1>
                    {
                        props.hideToggle ? <></> : 
                        <div className="border-2 border-white rounded-md cursor-pointer" onClick={onToggle}>
                            {isOpen ? <MdOutlineKeyboardArrowUp /> : <MdOutlineKeyboardArrowDown />}
                        </div>
                    }
                </header>
            }
            {props.loading ? <DotLoader /> :
                <>
                    <div className="flex gap-4 lg:gap-8">
                        {
                            <div className="flex gap-4 lg:gap-8 pb-4">
                                <div className="flex-shrink-0">
                                    <div className="flex gap-1">
                                        <p className="md:text-xl underline underline-offset-2 decoration-2 pb-2">Net Worth</p>
                                        <Tooltip hasArrow label="This represents the total value of your assets and deposits, minus any liabilities or loans. 
                                                It's a snapshot of your financial position within Aave V3, 
                                                reflecting the current value of your holdings and borrowings.">
                                            <span className="cursor-pointer">
                                                <Icon as={RiQuestionLine} boxSize={4} />
                                            </span>
                                        </Tooltip>
                                    </div>
                                    <p className="md:text-xl font-bold relative">
                                        <sup className="text-xs absolute top-0 left-0 pr-1">$</sup>
                                        <span className="pl-2">
                                            {parseFloat(props.summary?.netWorthUSD ?? '0').toFixed(2)}
                                        </span>
                                    </p>
                                </div>
                                <div className="flex-shrink-0">
                                    <div className="flex gap-1">
                                        <p className="md:text-xl underline underline-offset-2 decoration-2 pb-2">Health Factor</p>
                                        <Tooltip hasArrow label="This metric indicates the safety of your deposited assets against your borrowings. 
                                                A higher number means greater safety, reducing the risk of liquidation. 
                                                It's calculated based on the value of your collateral versus your loans. 
                                                Keep it above the minimum threshold to avoid liquidation.">
                                            <span className="cursor-pointer">
                                                <Icon as={RiQuestionLine} boxSize={4} />
                                            </span>
                                        </Tooltip>
                                    </div>
                                    <p className="md:text-xl font-bold">{borrows?.length ? parseFloat(props.summary?.healthFactor ?? '0').toFixed(4) : 0}</p>
                                </div>
                            </div>
                        }
                    </div>
                    <Outlet />
                    {walletAddress && <div className={`overflow-hidden delay-100 animate ease-in-out duration-300 ${isOpen ? 'max-h-full' : 'max-h-0'}`}>
                        <div className="py-2">
                            <p className="text-lg underline font-bold">Supplies</p>
                            <div className="flex gap-8 py-2">
                                <span><strong>Balance:</strong> ${parseFloat(props.summary?.totalLiquidityUSD ?? '0').toFixed(2)}</span>
                                <span><strong>Collateral:</strong> ${parseFloat(props.summary?.totalCollateralUSD ?? '0').toFixed(2)}</span>
                            </div>
                            <table className="w-full">
                                <thead>
                                    <tr className="border-b-2 border-white">
                                        <th className="text-left w-1/5 font-light">Token</th>
                                        <th className="text-left w-1/5 font-light hidden md:table-cell">Balance</th>
                                        <th className="text-left w-1/5 font-light hidden md:table-cell">APY</th>
                                        <th className="text-left w-1/5 font-light">Collateral</th>
                                        <th className="text-left w-1/5 font-light"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !supplies || supplies?.length <= 0 ? <tr><td colSpan={5}><p className="text-center">No Assets Supplied</p></td></tr> :
                                            supplies.map((r) => {
                                                return (
                                                    <>
                                                        <tr key={r.underlyingAsset} onClick={(e: React.MouseEvent) => {
                                                            showAsset(e, r, "supply")
                                                        }}
                                                            className="w-full cursor-pointer border-b pb-2">
                                                            <td><TokenName token={{ address: r.underlyingAsset, name: r.reserve.name, symbol: r.reserve.symbol } as BitqueryToken}></TokenName></td>
                                                            <td className="hidden md:table-cell">
                                                                <div>
                                                                    <p>${parseFloat(r.underlyingBalanceUSD).toFixed(2)}</p>
                                                                    <p>{parseFloat(r.underlyingBalance).toFixed(6)} {r.reserve.symbol}</p>
                                                                </div>
                                                            </td>
                                                            <td className="hidden md:table-cell">
                                                                <p>{(parseFloat(r.reserve.supplyAPY) * 100).toFixed(4)}%</p>
                                                            </td>
                                                            <td>
                                                                <Switch id="aave-switch" isDisabled={true} checked={r.usageAsCollateralEnabledOnUser} changeEvent={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                    e.stopPropagation()
                                                                }} />
                                                            </td>
                                                            <td className="text-right font-semibold">
                                                                <ActionButton
                                                                    isDisabled={true} // TODO - enable when actions are active again
                                                                    onClick={(e: React.MouseEvent) => showAsset(e, r, "withdraw")}
                                                                    style="primary"
                                                                    classes="h-[2rem] px-4">Withdraw</ActionButton>
                                                            </td>
                                                        </tr>
                                                        <></>
                                                    </>
                                                );
                                            })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="pt-4">
                            <p className="text-lg underline font-bold">Borrows</p>
                            <div className="flex gap-8 py-2">
                                <span><strong>Debt:</strong> ${parseFloat(props.summary?.totalBorrowsUSD ?? '0').toFixed(2)}</span>
                                <span><strong>Borrowing Power:</strong> ${parseFloat(props.summary?.availableBorrowsUSD ?? '0').toFixed(2)}</span>
                            </div>
                            <table className="w-full">
                                <thead>
                                    <tr className="border-b-2 border-white">
                                        <th className="text-left w-1/5 font-light">Token</th>
                                        <th className="text-left w-1/5 font-light hidden md:table-cell">Balance</th>
                                        <th className="text-left w-1/5 font-light hidden md:table-cell">APY</th>
                                        <th className="text-left w-1/5 font-light hidden md:table-cell">Apy Type</th>
                                        <th className="text-left w-1/5 font-light"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!borrows || borrows?.length <= 0 ? <tr><td colSpan={5}><p className="text-center">No Assets Borrowed</p></td></tr> :
                                        borrows.map((r) => {
                                            return (
                                                <tr key={r.underlyingAsset} onClick={(e: React.MouseEvent) => showAsset(e, r, "supply")} className="w-full cursor-pointer border-b pb-2">
                                                    {
                                                        r.stableBorrows && parseFloat(r.stableBorrows) > 0 &&
                                                        <>
                                                            <td><TokenName token={{ address: r.underlyingAsset, name: r.reserve.name, symbol: r.reserve.symbol } as BitqueryToken}></TokenName></td>
                                                            <td className="hidden md:table-cell">
                                                                <div>
                                                                    <p>${parseFloat(r.stableBorrowsUSD).toFixed(2)}</p>
                                                                    <p>{parseFloat(r.stableBorrows).toFixed(6)} {r.reserve.symbol}</p>
                                                                </div>
                                                            </td>
                                                            <td className="hidden md:table-cell">
                                                                <p>{(parseFloat(r.stableBorrowAPY) * 100).toFixed(4)}%</p>
                                                            </td>
                                                            <td>Stable</td>
                                                            <td className="float-right p-4">
                                                                <div className="flex gap-1 font-semibold">
                                                                    <ActionButton
                                                                        isDisabled={true} // TODO - enable when actions active again
                                                                        onClick={(e: React.MouseEvent) => showAsset(e, r, "repay")}
                                                                        style="primary"
                                                                        type="button"
                                                                        classes="my-3">Repay</ActionButton>
                                                                    <ActionButton
                                                                        isDisabled={true} // TODO - enable when actions active again
                                                                        onClick={(e: React.MouseEvent) => showAsset(e, r, "borrow")}
                                                                        style="secondary"
                                                                        type="button"
                                                                        classes="my-3">Borrow</ActionButton>
                                                                </div>
                                                            </td>
                                                        </>
                                                    }
                                                    {
                                                        r.variableBorrows && parseFloat(r.variableBorrows) > 0 &&
                                                        <>
                                                            <td><TokenName token={{ address: r.underlyingAsset, name: r.reserve.name, symbol: r.reserve.symbol } as BitqueryToken}></TokenName></td>
                                                            <td className="hidden md:table-cell">
                                                                <div>
                                                                    <p>${parseFloat(r.variableBorrowsUSD).toFixed(2)}</p>
                                                                    <p>{parseFloat(r.variableBorrows).toFixed(6)} {r.reserve.symbol}</p>
                                                                </div>
                                                            </td>
                                                            <td className="hidden md:table-cell">
                                                                <p>{(parseFloat(r.reserve.variableBorrowAPY) * 100).toFixed(4)}%</p>
                                                            </td>
                                                            <td className="hidden md:table-cell">Variable</td>
                                                            <td className="float-right">
                                                                <div className="flex gap-1 font-semibold my-3">
                                                                    <ActionButton
                                                                        isDisabled={true} // TODO - enable when actions active again
                                                                        onClick={(e: React.MouseEvent) => showAsset(e, r, "repay")}
                                                                        style="primary"
                                                                        type="button"
                                                                        classes="h-[2rem] px-4">Repay</ActionButton>
                                                                    <ActionButton
                                                                        isDisabled={true} // TODO - enable when actions active again
                                                                        onClick={(e: React.MouseEvent) => showAsset(e, r, "borrow")}
                                                                        style="secondary"
                                                                        type="button">Borrow</ActionButton>
                                                                </div>
                                                            </td>
                                                        </>
                                                    }
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>}
                </>
            }
        </section>
    );
}

export default AavePositionsHome;
