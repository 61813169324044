import { Tabs, TabList, Tab, TabIndicator, TabPanels, TabPanel } from "@chakra-ui/react"
import Holdings from "./Holdings"
import Watchlist from "./watchlist/Watchlist"
import { DashboardChartToken } from "@BoolDigital/sizzle-types"
import { useState } from "react";

interface HoldingsWatchlistTabProps {
  handleTokenSelection: any;
  selectedToken: DashboardChartToken | undefined;
  setDisplayPricelessToken: React.Dispatch<React.SetStateAction<boolean>>;
  displayPricelessToken: boolean;
  setWatchlist: React.Dispatch<any>;
}
const HoldingsWatchlistTab = ({
  handleTokenSelection,
  selectedToken,
  setDisplayPricelessToken,
  displayPricelessToken,
  setWatchlist
}: HoldingsWatchlistTabProps) => {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <Tabs variant="line">
      <TabList>
        <Tab _selected={{ color: 'mint.400', fontWeight: '600' }} onClick={() => setActiveTab(0)}>
          <h2 className="text-2xl">Holdings</h2>
        </Tab>
        <Tab _selected={{ color: 'mint.400', fontWeight: '600' }} onClick={() => setActiveTab(1)}>
          <h2 className="text-2xl">Watchlist</h2>
        </Tab>
      </TabList>
      <TabIndicator
        mt="-1.5px"
        height="2px"
        bg="mint.400"
        borderRadius="1px"
      />
      <TabPanels>
        <TabPanel className={`transition-opacity duration-500 ${activeTab === 0 ? 'opacity-100' : 'opacity-0'}`}>
          <Holdings
            setSelectedToken={handleTokenSelection}
            selectedToken={selectedToken}
            setDisplayPricelessToken={setDisplayPricelessToken}
            displayPricelessToken={displayPricelessToken}
          />
        </TabPanel>
        <TabPanel className={`transition-opacity duration-500 ${activeTab === 1 ? 'opacity-100' : 'opacity-0'}`}>
          <Watchlist selectedToken={selectedToken} setSelectedToken={handleTokenSelection} setWatchlist={setWatchlist} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export default HoldingsWatchlistTab;