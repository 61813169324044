import defaultTokenIcon from "../../../assets/icons/global/defaultTokenIcon.svg"
import { useEffect } from "react";
import useToken from "../../../hooks/useToken";
import DotLoader from "../../global/DotLoader";

interface CompoundTokenDisplayProps {
  token: string;
  handleSymbolReceived: (symbol: string, token: string) => void;
}

const CompoundTokenDisplay: React.FC<CompoundTokenDisplayProps> = (props) => {
  const { token, loading: tokenLoading, err } = useToken(props?.token?.toLowerCase());

  useEffect(() => {
    if (token && props.token && props.handleSymbolReceived) {
      props.handleSymbolReceived(token.symbol.toUpperCase(), props.token);
    }
  }, [token]);

  const addDefaultSrc = (ev: any) => {
    ev.target.src = defaultTokenIcon
  }

  return (
    tokenLoading ? (
      <DotLoader />
    ) : (
      <div className="flex gap-2 items-center p-2">
        <div>
          <img onError={addDefaultSrc} src={token?.image_small ?? defaultTokenIcon} alt={`${props.token} token icon`} className="w-[32px] h-[32px]" />
        </div>
        <p>{token?.symbol.toUpperCase()}</p>
      </div>
    )
  );
}
export default CompoundTokenDisplay;