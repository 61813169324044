import { Collapse, useDisclosure, Tooltip, Icon } from "@chakra-ui/react";
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";
import { RiQuestionLine } from "react-icons/ri";
import { useEffect, useState } from "react";
import DotLoader from "../../global/DotLoader";
import { useWalletContext } from "../../../hooks/useWalletContext";
import { Switch } from "../../global/Switch";
import { ethers } from "ethers";
import CompoundTokenDisplay from "./CompoundTokenDisplay";
import { formatLargeNumber, formatNumbers } from "../../../helpers/general";
import { SizzleAnimated } from "../../global/SizzleAnimated";
import compoundLogo from "../../../assets/images/protocol-logos/compound-compound-icon.png";

type Asset = {
  asset: string;
  priceFeed: string;
  tokenLiquidationFactor: string;
  price: number;
  scale: string;
  borrowCollateralFactor: string;
  collateralBalance: string;
  assetBalance: number;
  supplyCap: number;
};

const CompoundPositionsHome: React.FC<any> = (props) => {
  const { walletAddress, readOnly } = useWalletContext();
  const [showEth, setShowEth] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false)
  const { isOpen, onToggle, onOpen } = useDisclosure();
  const [symbols, setSymbols] = useState<{ [key: string]: string }>({});

  const tokenToDisplay: string = showEth ? "ETH" : "USDC";

  useEffect(() => {
    if (props.startOpen) {
      onOpen();
    }
  }, []);

  useEffect(() => {
    if (props?.summary?.length) {
      triggerUserData();
    }
  }, [props?.summary])

  useEffect(() => {
    triggerUserData()
  }, [showEth])

  const triggerUserData = () => {
    setLoading(true)
    if (props?.summary?.length) {
      const data: any = props?.summary?.find((token: any) => token?.name === tokenToDisplay);
      setUserInfo(data);
      setLoading(false)
    }
  }

  const formatTokenPrice = (bal: any) => {
    if (bal?.hex) {
      const balance: number = parseFloat(ethers.utils.formatUnits(bal, userInfo.decimals))
      return balance.toFixed(2)
    }
    return 0
  }

  const handleSymbolReceived = (symbol: string, token: string) => {
    setSymbols(prevSymbols => ({
      ...prevSymbols,
      [token]: symbol,
    }));
  };

  const assets: Asset[] | undefined = userInfo?.assets;

  return (
    <section className="font-primary">
      {!props.hideHeader && (
        <header className="flex gap-2 items-center py-2">
          <img src={compoundLogo} alt="Compound logo" className="w-[32px] h-[32px]" />
          <h1 className="text-2xl py-4">Compound</h1>
          {
            props.hideToggle ? <></> : 
            <div className="border-2 border-white rounded-md cursor-pointer" onClick={onToggle}>
              {isOpen ? <MdOutlineKeyboardArrowUp /> : <MdOutlineKeyboardArrowDown />}
            </div>
          }
        </header>)}
      {!props.loading && userInfo ?
        <>
          <div className="flex gap-4 lg:gap-8">
            <section>
              <h3 className="md:text-xl underline underline-offset-2 decoration-2 pb-2">{userInfo?.name === "USDC" ? "USDC" : "WETH"} Balance</h3>

              <h3 className="md:text-xl font-bold relative">
                <sup className="text-xs absolute top-0 left-0 pr-1">{userInfo.name === "USDC" ? "$" : "Ξ"}</sup>
                <span className="pl-2">
                  {formatTokenPrice(userInfo?.totalBalance)}
                </span>
              </h3>
            </section>
            <section>
              <div className="flex gap-1">
                <h3 className="md:text-xl underline underline-offset-2 decoration-2 pb-2">Total Supplied</h3>
                <Tooltip hasArrow label={`Total balance of supplied collateral for ${userInfo.name}.`}
                  aria-label={`Total balance of supplied collateral for ${userInfo.name}.`}>
                  <span className="cursor-pointer">
                    <Icon as={RiQuestionLine} boxSize={4} />
                  </span>
                </Tooltip>
              </div>
              <h3 className="md:text-xl font-bold relative">
                <sup className="text-xs absolute top-0 left-0 pr-1">$</sup>
                <span className="pl-2">
                  {formatLargeNumber(parseFloat(userInfo.collateralValueUsd).toFixed(2))}
                </span>
              </h3>
            </section>
            <section>
              <div className="flex gap-1">
                <h3 className="md:text-xl underline underline-offset-2 decoration-2 pb-2">Available to Borrow</h3>
                <Tooltip hasArrow label="Max borrowing capacity based on supplied collateral."
                  aria-label="Max borrowing capacity based on supplied collateral">
                  <span className="cursor-pointer">
                    <Icon as={RiQuestionLine} boxSize={4} />
                  </span>
                </Tooltip>
              </div>
              <h3 className="md:text-xl font-bold relative">
                <sup className="text-xs absolute top-0 left-0 pr-1">$</sup>
                <span className="pl-2">
                  {formatLargeNumber(userInfo?.maxBorrowable?.toFixed(2))}
                </span>
              </h3>
            </section>
            <section>
              <div className="flex gap-1">
                <h3 className="md:text-xl underline underline-offset-2 decoration-2 pb-2">Already Borrowed</h3>
                <Tooltip hasArrow label="Current outstanding balance owed."
                  aria-label="Current outstanding balance owed.">
                  <span className="cursor-pointer">
                    <Icon as={RiQuestionLine} boxSize={4} />
                  </span>
                </Tooltip>
              </div>
              <h3 className="md:text-xl font-bold relative">
                <sup className="text-xs absolute top-0 left-0 pr-1">$</sup>
                <span className="pl-2">
                  {formatLargeNumber(userInfo?.alreadyBorrowed?.toFixed(2))}
                </span>
              </h3>
            </section>
          </div>
          <div className="flex gap-8 py-2">
            <span><strong>TVL: </strong>${showEth ? formatLargeNumber(userInfo.tvl * userInfo.assetPrice) : formatLargeNumber(userInfo.tvl) ?? '0'}</span>
            <span><strong>Liquidatable? </strong>{userInfo?.isLiquidatable ? "Yes" : "No"}</span>
            <span><strong>Collateralized? </strong>{userInfo?.isCollateralized ? "Yes" : "No"}</span>
          </div>
          <Collapse in={isOpen}>
            {props?.summary?.length > 1 && <div className="flex gap-2 py-2 items-center">
              <p className="text-sm">USDC</p>
              <Switch checked={showEth} changeEvent={() => setShowEth(!showEth)} />
              <p className="text-sm">ETH</p>
            </div>}
            {walletAddress && <div className={`overflow-hidden delay-100 animate ease-in-out duration-300 ${isOpen ? 'max-h-full' : 'max-h-0'}`}>
              {loading ?
                <SizzleAnimated />
                :
                <div className="py-2">
                  <table className="w-full">
                    <thead>
                      <tr className="border-b-2 border-white">
                        <th className="text-left w-1/5 font-light">Token</th>
                        <th className="text-left w-1/5 font-light">Price</th>
                        <th className="text-left w-1/5 font-light">Supplied</th>
                        <th className="text-left w-1/5 font-light">Collateral Factor</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        !assets || assets?.length <= 0 ? <tr><td colSpan={5}><p className="text-center">No Assets Supplied</p></td></tr> :
                          assets && assets.map((r: any) => {
                            const symbol = symbols[r.asset] || "";
                            return (
                              <tr key={r.token} onClick={(e: React.MouseEvent) => console.log("supply")} className="w-full cursor-pointer border-b pb-2">
                                <td>
                                  <CompoundTokenDisplay token={r.asset} handleSymbolReceived={handleSymbolReceived} />
                                </td>
                                <td>
                                  {showEth ?
                                    <p>${formatLargeNumber((r.price).toFixed(2))}</p>
                                    :
                                    <p>${formatLargeNumber(parseFloat(r.price).toFixed(2))}</p>
                                  }
                                </td>
                                <td>
                                  <p>{formatNumbers((r.collateralBalance / r.scale).toFixed(2))} {symbol}</p>
                                  <p className="text-white text-opacity-70">${formatNumbers((r.collateralBalance / r.scale * r.price).toFixed(2))}</p>
                                </td>
                                <td>
                                  <p>{r.borrowCollateralFactor / 1e16}%</p>
                                </td>
                              </tr>
                            );
                          })
                      }
                    </tbody>
                  </table>
                </div>}
            </div>}
          </Collapse>
        </>
        :
        <DotLoader />
      }
    </section>
  );
}

export default CompoundPositionsHome;