import { useState, useEffect } from 'react';

function useIsMobile() {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    // Function to update isMobile state based on window width
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Initial call to set isMobile state
    updateIsMobile();

    // Event listener to track changes in window size
    window.addEventListener("resize", updateIsMobile);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", updateIsMobile);
    };
  }, []); // Empty dependency array ensures the effect runs only once

  return isMobile;
}

export default useIsMobile;