import { BitqueryToken } from "@BoolDigital/sizzle-types";
import { ethers } from "ethers";
import defaultTokenIcon from "../../assets/icons/global/defaultTokenIcon.svg"
interface TokenNameProps {
    token: BitqueryToken;
    isSelected?: boolean;
    hideName?: boolean;
}

const TokenName: React.FC<TokenNameProps> = (props) => {
    const addDefaultSrc = (ev: any) => {
        ev.target.src = defaultTokenIcon // @TODO replace with desired default icon
    }
    let url;
    if (!props.token?.native) {
        url = `https://github.com/trustwallet/assets/blob/master/blockchains/ethereum/assets/${ethers.utils.getAddress(props.token.address)}/logo.png?raw=true`
    } else {
        url = `https://github.com/trustwallet/assets/blob/master/blockchains/ethereum/info/logo.png?raw=true`
    }

    return (
        <div className="flex gap-2 items-center p-2">
            <div className="h-[48px] w-[48px]">
                <img onError={addDefaultSrc} src={url} alt={`${props.token.name} token icon`} />
            </div>
            <div>
                {!props.hideName ? <p>{props.token?.name}</p> : <></>}
                <p className={props.isSelected ? "text-black" : props.hideName ? 'text-white' : "text-[#ffffff7a]"}> {props.token?.symbol}</p>
            </div>
        </div >
    );
}

export default TokenName;