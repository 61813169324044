import React, { useEffect, useState } from 'react';
import { Button, ButtonProps, CircularProgress } from '@chakra-ui/react';
import { useWeb3Modal } from '@web3modal/react';
import { trackEvent } from '../helpers/googleAnalyticsHelpers';

interface ConnectWalletButtonProps extends ButtonProps {
    closeFn?: () => void;
}

const ConnectWalletButton: React.FC<ConnectWalletButtonProps> = (props) => {
    const { isOpen, open, close, setDefaultChain } = useWeb3Modal();

    return (
        <Button
            {...props}
            onClick={() => {
                if (props.closeFn) {
                    props.closeFn();
                }
                trackEvent({
                    category: 'connect_wallet',
                    action: 'open_walletconnect',
                    label: 'Connect Wallet Button in Modal',
                });
                open()
            }}
        >
            {isOpen ? (
                <>
                    <CircularProgress isIndeterminate size="1rem" color="mint.400" mr={2} />
                    {"Connecting..."}
                </>
            ) : (
                props.children
            )}
        </Button>
    );
};

export default ConnectWalletButton;