import { useEffect, useState } from "react";
import { FormatReserveUSDResponse, FormatUserSummaryResponse } from "@aave/math-utils";
import { ReserveDataHumanized } from "@aave/contract-helpers";
import { UniswapPositionData } from "@BoolDigital/sizzle-types";
import { ConfigVariables } from "../config";

const useAllPositions = (currentAccount: string | undefined, refreshKey?: number) => {
    const [aaveUserSummary, setAaveUserSummary] = useState<FormatUserSummaryResponse<ReserveDataHumanized & FormatReserveUSDResponse> | undefined>();
    const [uniswapPositions, setUniswapPositions] = useState<UniswapPositionData[]>();
    const [compoundUserSummary, setCompoundUserSummary] = useState<any[]>();
    const [lidoSummary, setLidoSummary] = useState<any>();
    const [rocketPoolUserSummary, setRocketPoolUserSummary] = useState<any>();
    const [loadingAave, setLoadingAave] = useState<boolean>(false);
    const [loadingUniswap, setLoadingUniswap] = useState<boolean>(false);
    const [loadingLido, setLoadingLido] = useState<boolean>(false);
    const [loadingCompound, setLoadingCompound] = useState<boolean>(false);
    const [loadingRocketPool, setLoadingRocketPool] = useState<boolean>(false)
    useEffect(() => {
        const fetchAaveSummary = async () => {
            if (!currentAccount) return;

            const queryParams = new URLSearchParams({
                currentAccount: currentAccount || '',
            })
            setLoadingAave(true);
            const response = await fetch(`${ConfigVariables.SERVER_URL}/portfolio/aave-user-summary?${queryParams}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            setLoadingAave(false);

            const userSummary = data?.aaveUserSummary;
            setAaveUserSummary(userSummary);
        }

        const fetchLidoSummary = async () => {
            if (!currentAccount) return;

            const queryParams = new URLSearchParams({
                currentAccount: currentAccount || '',
            })
            setLoadingLido(true);
            const response = await fetch(`${ConfigVariables.SERVER_URL}/portfolio/lido-user-summary?${queryParams}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            setLoadingLido(false);
            setLidoSummary(data);
        }

        const fetchUniswapSummary = async () => {
            if (!currentAccount) return;

            const queryParams = new URLSearchParams({
                currentAccount: currentAccount || '',
            })
            setLoadingUniswap(true);
            const response = await fetch(`${ConfigVariables.SERVER_URL}/portfolio/uniswap-user-summary?${queryParams}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            setLoadingUniswap(false);
            const positions = data?.uniswapUserSummary;
            setUniswapPositions(positions);
        }

        const fetchCompoundSummary = async () => {
            if (!currentAccount) return;

            const queryParams = new URLSearchParams({
                currentAccount: currentAccount || '',
            })
            setLoadingCompound(true);
            const response = await fetch(`${ConfigVariables.SERVER_URL}/portfolio/compound-user-summary?${queryParams}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();
            setLoadingCompound(false);

            setCompoundUserSummary(data);
        }

        const fetchRocketPoolSummary = async () => {
            if (!currentAccount) return;

            const queryParams = new URLSearchParams({
                currentAccount: currentAccount || '',
            })
            setLoadingRocketPool(true);
            const response = await fetch(`${ConfigVariables.SERVER_URL}/portfolio/rocketpool-user-summary?${queryParams}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();
            setLoadingRocketPool(false);

            setRocketPoolUserSummary(data);
        }

        fetchAaveSummary();
        fetchUniswapSummary();
        fetchLidoSummary();
        fetchCompoundSummary();
        fetchRocketPoolSummary();
    }, [currentAccount, refreshKey]);

    return { aaveUserSummary, loadingAave, uniswapPositions, loadingUniswap, lidoSummary, loadingLido, compoundUserSummary, loadingCompound, rocketPoolUserSummary, loadingRocketPool };
};

export default useAllPositions;