import { 
    Box, Button, Flex, Modal, ModalBody, ModalContent, ModalOverlay, 
    Text, Image, useDisclosure, Heading, CircularProgress, ModalCloseButton, Center 
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { DefiRate } from "@BoolDigital/sizzle-types";
import { formatRateLabel } from "../../../helpers/watchlistHelpers";
import { MdEdit } from "react-icons/md";
import { ConfigVariables } from "../../../config";
import { useWatchedTokenRatesWatched } from "../../../hooks/useWatchedTokenRatesWatched";
import { SizzleAnimated } from "../../global/SizzleAnimated";
import { trackEvent } from "../../../helpers/googleAnalyticsHelpers";
import { useWalletContext } from "../../../hooks/useWalletContext";

interface EditWatchlistTokenProps {
    user: any;
    tokenToEdit: any;
    onReloadWatchlist: () => void;
}

const EditWatchlistToken: React.FC<EditWatchlistTokenProps> = ({ user, tokenToEdit, onReloadWatchlist }) => {
    const [defiRates, setDefiRates] = useState<DefiRate[]>([]);
    const { walletAddress } = useWalletContext();
    const [loadingDefiRates, setLoadingDefiRates] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [removeLoading, setRemoveLoading] = useState(false);
    const [selectedDefiRateIds, setSelectedDefiRateIds] = useState<string[]>([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { rates, isLoading, isError } = useWatchedTokenRatesWatched(tokenToEdit?.userTokenWatchlistId);

    const customScrollbar = { /* ... same as before ... */ };

    // useEffect(() => {
    //     onModalToggle(isOpen)
    // }, [isOpen])

    useEffect(() => {
        if(isOpen) {
            setLoadingDefiRates(true);
            fetch(`${ConfigVariables.SERVER_URL}/defi/protocols/rates/${tokenToEdit?.token?.address}`)
                .then(response => response.json())
                .then(data => {
                    setDefiRates(data ?? [])
                })
                .catch((e) => {
                    console.log(`Error getting rates: ${e}`)
                })
                .finally(() => {
                    setLoadingDefiRates(false);
                });
        }
    }, [isOpen]);

    useEffect(() => {
        if (rates) {
            setSelectedDefiRateIds(rates);
        }
    }, [rates, isOpen]);

    function toggleRateSelection(id: string) { 
        setSelectedDefiRateIds(prevIds => {
            if (prevIds.includes(id)) {
                trackEvent({
                    category: 'watchlist',
                    action: 'deselect-defi-rate',
                    label: 'Deselect DeFi Rate to Unwatch',
                    additionalParams: {
                        'source': 'edit-token',
                        'wallet_address': walletAddress,
                        'token_address': tokenToEdit?.token?.address,
                        'rate_key': id,
                    }
                });
                return prevIds.filter(rateId => rateId !== id);
            } else {
                trackEvent({
                    category: 'watchlist',
                    action: 'select-defi-rate',
                    label: 'Select DeFi Rate to Watch',
                    additionalParams: {
                        'source': 'edit-token',
                        'wallet_address': walletAddress,
                        'token_address': tokenToEdit?.token?.address,
                        'rate_key': id,
                    }
                });
                return [...prevIds, id];
            }
        });
    }

    async function saveEditedToken() {
        const selectedDefiRates = selectedDefiRateIds.map((id) => {
            return defiRates.find((r) => r.key === id);
        });
    
        const payload = {
            userAddress: user.address,
            token: tokenToEdit,
            rates: selectedDefiRates,
        };
    
        setSaveLoading(true);
        try {
            const response = await fetch(`${ConfigVariables.SERVER_URL}/defi/edit-watchlist-token`, {
                method: 'POST', // Assuming a PUT method for updates
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });
    
            const data = await response.json();
            if (response.ok) {
                setSaveLoading(false);
                trackEvent({
                    category: 'watchlist',
                    action: 'save-watchlist-token',
                    label: 'Save Watchlist Token',
                    additionalParams: {
                        'source': 'edit-token',
                        'wallet_address': walletAddress,
                        'token_address': tokenToEdit?.token?.address,
                        'number_of_watched_rates': selectedDefiRateIds?.length,
                        'success': true
                    }
                });
                onReloadWatchlist();
                onClose();
            } else {
                console.error(data.message || "Error updating token");
            }
        } catch (e) {
            trackEvent({
                category: 'watchlist',
                action: 'save-watchlist-token',
                label: 'Save Watchlist Token',
                additionalParams: {
                    'source': 'edit-token',
                    'wallet_address': walletAddress,
                    'token_address': tokenToEdit?.token?.address,
                    'number_of_watched_rates': selectedDefiRateIds?.length,
                    'success': false
                }
            });
            console.log(`Error updating token: ${e}`);
        }
        setSaveLoading(false);
    };

    async function removeEditedToken() {
        const payload = {
            userAddress: user.address,
            token: tokenToEdit,
        };
    
        setRemoveLoading(true);
        try {
            const response = await fetch(`${ConfigVariables.SERVER_URL}/defi/remove-watchlist-token`, {
                method: 'POST', // Assuming a PUT method for updates
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });
    
            const data = await response.json();
            if (response.ok) {
                setSaveLoading(false);
                trackEvent({
                    category: 'watchlist',
                    action: 'remove-token',
                    label: 'Remove Token from Watchlist',
                    additionalParams: {
                        'wallet_address': walletAddress,
                        'token_address': tokenToEdit?.token?.address,
                        'success': true
                    }
                });
                onReloadWatchlist();
                onClose();
            } else {
                console.error(data.message || "Error removing token");
            }
        } catch (e) {
            trackEvent({
                category: 'watchlist',
                action: 'remove-token',
                label: 'Remove Token from Watchlist',
                additionalParams: {
                    'wallet_address': walletAddress,
                    'token_address': tokenToEdit?.token?.address,
                    'success': false
                }
            });
            console.log(`Error removing token: ${e}`);
        }
        setRemoveLoading(false);
    }

    return (
        <Box>
            <Button variant="ghost" onClick={() => {
                onOpen();
                trackEvent({
                    category: 'watchlist',
                    action: 'open-edit-watchlist-token-modal',
                    label: 'Edit Watchlist Token Button',
                    additionalParams: {
                        'wallet_address': walletAddress,
                        'token_address': tokenToEdit?.token?.address,
                    }
                });
            }} _hover={{ bg: "none", color: "gray.600" }} _active={{ bg: "none" }} p={0}>
                <MdEdit color={'white'}/>
            </Button>
            <Modal isOpen={isOpen} onClose={() => {
                setDefiRates([]);
                setSelectedDefiRateIds([]);
                onClose();
            }} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalBody p={0}>
                        <Heading pr={4} pl={4} pt={4} size={'sm'}>Edit Token Watchlist</Heading>
                        <ModalCloseButton />
                        <Flex p={4} alignItems={'center'} gap={4}>
                            <Image src={tokenToEdit?.token?.image_small} alt={tokenToEdit?.token?.symbol} boxSize="32px" />
                            <Text fontSize="lg">{tokenToEdit?.token?.name}</Text>
                        </Flex>
                        <Box maxH={'300px'} overflowY={'scroll'} p={4} sx={customScrollbar}>
                            <Text>Available Protocols</Text>
                            {
                                loadingDefiRates ? <Center pt={4}><SizzleAnimated size={'md'} /></Center> :
                                defiRates && defiRates.map((r) => {
                                    const isSelected = selectedDefiRateIds.includes(r.key ?? '-1');

                                    return (
                                        <Flex key={r.key} 
                                            align="center" 
                                            gap={4} 
                                            justifyContent={'space-between'}
                                            bg={isSelected ? "mint.300" : undefined}
                                            textColor={isSelected ? "black" : undefined}
                                            borderRadius="md"
                                            p={2}
                                            mt={2}
                                            cursor="pointer"
                                            _hover={{ bg: !isSelected ? "mint.100" : "red.100", textColor: "black" }}
                                            onClick={() => {
                                                toggleRateSelection(r.key ?? '');
                                            }}
                                        >
                                            <Flex alignItems={'center'} gap={4}>
                                                <Image src={r.protocolLogoUrl} alt={r.protocol} boxSize="32px" />
                                                <Text fontSize={'sm'}>{formatRateLabel(r)}</Text>
                                            </Flex>
                                            <Text fontSize={'sm'}>{r.displayRate}%</Text>
                                        </Flex>
                                    );
                                })
                            }
                        </Box>
                        <Box m={4}>
                            <Button 
                                w={'100%'}
                                bgColor={'mint.500'}
                                onClick={saveEditedToken}
                                isDisabled={saveLoading}
                            >
                                {saveLoading ? <CircularProgress isIndeterminate color={'mint.300'} size={'20px'} /> : 'Update Token'}
                            </Button>
                        </Box>
                        <Box m={4}>
                            <Button 
                                w={'100%'}
                                bgColor={'transparent'}
                                borderColor={'red.500'}
                                borderWidth={'2px'}
                                _hover={{bgColor: 'rgba(255, 120, 120, 0.25)'}}
                                onClick={removeEditedToken}
                                isDisabled={removeLoading}
                            >
                                {removeLoading ? <CircularProgress isIndeterminate color={'red.300'} size={'20px'} /> : 'Remove Token'}
                            </Button>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    )
}

export default EditWatchlistToken;