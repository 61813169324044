import { ethers } from "ethers";
import useAllPositions from "../../hooks/useAllPositions";
import { useProvider } from "wagmi";
import { useWalletContext } from "../../hooks/useWalletContext";
import AavePositionsHome from "../protocols/aave/AavePositionsHome";
import UniswapPositionsHome from "../protocols/uniswap/UniswapPositionsHome";
import CompoundPositionsHome from "../protocols/compound/CompoundPositionsHome";
import LidoPositionsHome from "../protocols/lido/LidoPositionsHome";
import RocketPoolPositionsHome from "../protocols/rocketPool/RocketPoolPositionsHome";
import { FaWallet } from "react-icons/fa";
import { Carousel } from "../carousel/Carousel";
import { IconButton } from "@chakra-ui/button";
import aaveLogo from '../../assets/images/protocol-logos/aave-aave-logo.png';
import uniswapLogo from "../../assets/images/protocol-logos/uniswap-uni-logo.png";
import compoundLogo from "../../assets/images/protocol-logos/compound-compound-icon.png";
import lidoLogo from '../../assets/images/protocol-logos/lido-lido-logo.png';
import rocketPoolIcon from "../../assets/images/protocol-logos/rocket-pool-icon.png";
import { useEffect, useState } from "react";
import DotLoader from "../global/DotLoader";
import { formatLargeNumber } from "../../helpers/general";

const Positions = () => {
    const { walletAddress, readOnly } = useWalletContext();
    const {
        aaveUserSummary,
        loadingAave,
        uniswapPositions,
        loadingUniswap,
        lidoSummary,
        loadingLido,
        compoundUserSummary,
        loadingCompound,
        rocketPoolUserSummary,
        loadingRocketPool
    } = useAllPositions(walletAddress);
    const [selectedProtocol, setSelectedProtocol] = useState<'aave' | 'uniswap' | 'compound' | 'lido' | 'rocketpool' | null>(null);
    const [fade, setFade] = useState('opacity-0');
    const [currentComponent, setCurrentComponent] = useState<any>();

    const aaveTotal = parseFloat(aaveUserSummary?.netWorthUSD ?? '0');
    const uniswapTotal = uniswapPositions?.reduce((accumulator, p) => {
        return accumulator
            + ((p.token0PriceUSD ?? 0) * (parseFloat(p.amount0 ?? '0')))
            + ((p.token1PriceUSD ?? 0) * (parseFloat(p.amount1 ?? '0')))
    }, 0) ?? 0;
    const compoundTotal = compoundUserSummary?.reduce((accumulator, a) => {
        return accumulator + (parseFloat(a.collateralValueUsd ?? 0) - parseFloat(a.alreadyBorrowed ?? 0))
    }, 0) ?? 0;
    const lidoTotal = parseFloat(lidoSummary?.usdBalance ?? 0);
    const rocketpoolTotal = parseFloat(rocketPoolUserSummary?.rethBalance ?? 0) * parseFloat(rocketPoolUserSummary?.rETHPrice ?? 0);
    const totalBalance = aaveTotal + uniswapTotal + compoundTotal + lidoTotal + rocketpoolTotal;

    useEffect(() => {
        setFade('opacity-0');

        // Wait for the fade-out transition, then switch component and fade in
        const timeoutId = setTimeout(() => {
          switch (selectedProtocol) {
            case 'aave':
              setCurrentComponent(<AavePositionsHome startOpen={true} hideToggle={true} summary={aaveUserSummary} loading={loadingAave} />);
              break;
            case 'uniswap':
              setCurrentComponent(<UniswapPositionsHome startOpen={true} hideToggle={true} positions={uniswapPositions} loading={loadingUniswap} />);
              break;
            case 'lido':
              setCurrentComponent(<LidoPositionsHome summary={lidoSummary} loading={loadingLido} />);
              break;
            case 'compound':
              setCurrentComponent(<CompoundPositionsHome startOpen={true} hideToggle={true} summary={compoundUserSummary} loading={loadingCompound} />);
              break;
            case 'rocketpool':
              setCurrentComponent(<RocketPoolPositionsHome summary={rocketPoolUserSummary} loading={loadingRocketPool} />);
              break;
            default:
              setCurrentComponent(
                <div>
                    {loadingAave || loadingUniswap || loadingLido || loadingCompound || loadingRocketPool ? <DotLoader /> :
                        <>
                            <div className="flex pt-2 gap-4 lg:gap-8 w-100 flex-wrap">
                                <section className="flex-shrink-0">
                                    <h3 className="md:text-xl underline underline-offset-2 decoration-2 pb-2">Total DeFi Positions</h3>

                                    <h3 className="md:text-xl font-bold relative">
                                        <sup className="text-xs absolute top-0 left-0 pr-1">$</sup>
                                        <span className="pl-2">
                                            {formatLargeNumber(parseFloat(totalBalance).toFixed(4))}
                                        </span>
                                    </h3>
                                </section>
                            </div>
                        </>
                    }
                </div>
              );
          }
    
          // Fade in the new component
          setFade('opacity-100');
        }, 200); // Duration should match Tailwind's transition duration
    
        return () => clearTimeout(timeoutId);
    }, [selectedProtocol])

    return (
        <div className={`w-[100%]`}>
            <h2 className="text-2xl font-bold py-2">Positions</h2>
            <div className={`flex gap-2 w-[100%] py-2`}>
                <div className={`${selectedProtocol ? 'text-white' : 'text-[#73B0BE]'} rounded-full w-[40px] h-[40px] border-2 ${selectedProtocol ? 'border-white' : 'border-[#73B0BE]'} flex items-center justify-center cursor-pointer hover:scale-110 transition-transform duration-200 ease-in-out`}
                    onClick={() => {
                        setSelectedProtocol(null);
                    }}
                >
                    <FaWallet />
                </div>
                <div className={`rounded-full w-[40px] h-[40px] border-2 border-white flex items-center justify-center cursor-pointer hover:scale-110 transition-transform duration-200 ease-in-out`}
                    onClick={() => {
                        setSelectedProtocol('aave');
                    }}>
                    <img src={aaveLogo} alt="Aave logo" className={`${selectedProtocol === 'aave' ? '' : 'filter grayscale'} w-[38px] h-[38px]`} />
                </div>
                <div className={`rounded-full w-[40px] h-[40px] border-2 border-white flex items-center justify-center cursor-pointer hover:scale-110 transition-transform duration-200 ease-in-out`}
                    onClick={() => {
                        setSelectedProtocol('uniswap');
                    }}>
                    <img src={uniswapLogo} alt="Uniswap logo" className={`${selectedProtocol === 'uniswap' ? '' : 'filter grayscale contrast-0'} w-[32px] h-[32px]`} />
                </div>
                <div className={`rounded-full w-[40px] h-[40px] border-2 border-white flex items-center justify-center cursor-pointer hover:scale-110 transition-transform duration-200 ease-in-out`}
                    onClick={() => {
                        setSelectedProtocol('compound');
                    }}>
                    <img src={compoundLogo} alt="Compound logo" className={`${selectedProtocol === 'compound' ? '' : 'filter grayscale'} w-[32px] h-[32px]`} />
                </div>
                <div className={`rounded-full w-[40px] h-[40px] border-2 border-white flex items-center justify-center cursor-pointer hover:scale-110 transition-transform duration-200 ease-in-out`}
                    onClick={() => {
                        setSelectedProtocol('lido');
                    }}>
                    <img src={lidoLogo} alt="Lido logo" className={`${selectedProtocol === 'lido' ? '' : 'filter grayscale'} w-[38px] h-[38px] rounded-full`} />
                </div>
                <div className={`rounded-full w-[40px] h-[40px] border-2 border-white flex items-center justify-center cursor-pointer hover:scale-110 transition-transform duration-200 ease-in-out`}
                    onClick={() => {
                        setSelectedProtocol('rocketpool');
                    }}>
                    <img src={rocketPoolIcon} alt="RocketPool logo" className={`${selectedProtocol === 'rocketpool' ? '' : 'filter grayscale'} w-[38px] h-[38px]"`} />
                </div>
            </div>
            <div className={`transition-opacity duration-200 ${fade}`}>
                {currentComponent}
            </div>
        </div>
    );
}

export default Positions;